.header {
  background-color: #ffffff !important;
  height: 50px !important;
  position: sticky !important;
  top: 0;
  z-index: 1100;
  /* max-width: 1440px; */
  margin-left: auto;
  margin-right: auto;
}

.header_scrolled {
  background-color: #ffffff !important;
  height: 50px !important;
  position: sticky !important;
  top: 0;
  z-index: 1100;
  /* max-width: 1440px; */
  margin-left: auto;
  margin-right: auto;
  box-shadow: -1px 4px 2px -2px gray !important;
  transition: all 0.1s ease-in-out;
}

.language-component {
  text-align: left;
}

.logo-component {
  text-align: right;
}

.flag {
  width: 35px;
  height: 35px;
  cursor: pointer;
  margin: 5px;
}

.header-mobile {
  background-color: #ffffff !important;
  height: 50px !important;
  position: sticky !important;
  top: 0;
  z-index: 1100;
  display: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 5px;
}
.topbar-image {
  width: 90%;
  height: auto;
  margin-left: 25px;
}

/* p {
  font-size: 15px;
  text-align: center;
} */

@media (max-width: 1400px) {
  .header-mobile {
    display: none;
  }
  .logo-component {
    text-align: left;
  }
}

@media (max-width: 1200px) {
  .header-mobile {
    display: none;
  }
  .flag {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin: 4px;
  }

  .topbar-image {
    width: 90%;
    height: auto;
  }

  .nav-link {
    font-size: 16px !important;
    text-align: center;
  }
}

@media (max-width: 992px) {
  .header-mobile {
    display: flex;
  }
  .header {
    display: none;
  }
  .header_scrolled {
    display: none;
  }
  .topbar-image-mobile {
    width: 80%;
    height: auto;
  }
  .navbar-nav {
    background-color: #ffffff;
    text-align: center;
  }
  .nav-link {
    color: #565656;
    border-radius: 5px;
  }
  .language-component-mobile {
    display: flex;
    justify-content: space-evenly;
    /* margin: 10px; */
  }

  .navbar-dropdown-item {
    font-size: 12px;
  }

  .flag {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 992px) {
  .app__header_decor {
    max-width: 1410px;
    height: 20px;
    background-image: url('../images/top_arrow_v1.png');
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
    /* background-position: center; */
  }

  .app__header_decor_scrolled {
    max-width: 1410px;
    height: 20px;
    background-image: url('../images/top_arrow_v1.png');
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    /* background-position: center; */
  }

  .app__header_decor_hide {
    display: none;
  }
}
