.footer {
  background-color: #1072a2;
  position:relative;
  bottom: 0;
  min-height: 250px;
  margin-top: 0px !important;
  width: 100%;
}

.footer-text-container {
  max-width: 1920px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.footer-caption {
  font-size: 40px;
  text-transform: uppercase;
  font-weight: bold;
  color: #ffffff;
  margin-top: 0px !important;

  padding-top: 30px;
}

.footer-links {
  display: flex;
  justify-content: space-evenly;
  padding: 30px;
  max-width: 550px;
  width: 100%;
}

.footer-link-title {
  font-weight: bold;
  font-size: 20px;
}

.footer-apartmans-and-infos {
  text-transform: capitalize;
  color: #ffffff;
  font-size: 16px;
}

.footer-aszf {
  text-transform: uppercase;
}

.footer-copyright {
  display: flex;
  margin-top: 20px;
  font-weight: bold;
  color: #565656;
  justify-content: center;
}



@media (max-width: 620px) {
  .footer-caption {
    margin-left: 25px;
  }

  .footer-text-container {
    flex-direction: column;
  }

  .footer-links {
    max-width: 450px;
    justify-content: unset;
    flex-direction: column;
  }

  .footer-mobile-links {
    text-indent: 10px;
  }

  .footer-copyright {
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 426px ) {
  .footer-copyright {
    width: 60%;
  }
}

@media (max-width: 321px ) {
  .footer-copyright {
    font-size: 13px;
  }
}